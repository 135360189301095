/**
 * Formatar Numero
 * @param number
 * @param decimals
 * @param dec_point
 * @param thousands_sep
 * @returns {Number}
 */
function number_format( number, decimals, dec_point, thousands_sep ) {
	// %	nota 1: Para 1000.55 retorna com precisão 1 no FF/Opera é 1,000.5, mas no IE é 1,000.6
	// *	 exemplo 1: number_format(1234.56);
	// *	 retorno 1: '1,235'
	// *	 exemplo 2: number_format(1234.56, 2, ',', ' ');
	// *	 retorno 2: '1 234,56'
	// *	 exemplo 3: number_format(1234.5678, 2, '.', '');
	// *	 retorno 3: '1234.57'
	// *	 exemplo 4: number_format(67, 2, ',', '.');
	// *	 retorno 4: '67,00'
	// *	 exemplo 5: number_format(1000);
	// *	 retorno 5: '1,000'
	// *	 exemplo 6: number_format(67.311, 2);
	// *	 retorno 6: '67.31'
 
	var n = number, prec = decimals;
	n = !isFinite(+n) ? 0 : +n;
	prec = !isFinite(+prec) ? 0 : Math.abs(prec);
	var sep = (typeof thousands_sep == "undefined") ? ',' : thousands_sep;
	var dec = (typeof dec_point == "undefined") ? '.' : dec_point;
 
	var s = (prec > 0) ? n.toFixed(prec) : Math.round(n).toFixed(prec); //fix for IE parseFloat(0.55).toFixed(0) = 0;
 
	var abs = Math.abs(n).toFixed(prec);
	var _, i;
 
	if (abs >= 1000) {
		_ = abs.split(/\D/);
		i = _[0].length % 3 || 3;
 
		_[0] = s.slice(0,i + (n < 0)) +
			  _[0].slice(i).replace(/(\d{3})/g, sep+'$1');
 
		s = _.join(dec);
	} else {
		s = s.replace('.', dec);
	}
 
	return s;
}

/**
 * STR_PAD
 * @param input
 * @param pad_length
 * @param pad_string
 * @param pad_type
 * @returns {String}
 */
function str_pad (input, pad_length, pad_string, pad_type) {
	// Returns input string padded on the left or right to specified length with pad_string
	//
	// version: 1009.2513
	// discuss at: http://phpjs.org/functions/str_pad
	// +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
	// + namespaced by: Michael White (http://getsprink.com)
	// +	  input by: Marco van Oort
	// +   bugfixed by: Brett Zamir (http://brett-zamir.me)
	// *	 example 1: str_pad('Kevin van Zonneveld', 30, '-=', 'STR_PAD_LEFT');
	// *	 returns 1: '-=-=-=-=-=-Kevin van Zonneveld'
	// *	 example 2: str_pad('Kevin van Zonneveld', 30, '-', 'STR_PAD_BOTH');
	// *	 returns 2: '------Kevin van Zonneveld-----'
	var half = '', pad_to_go;
 
	var str_pad_repeater = function (s, len) {
		var collect = '', i;
 
		while (collect.length < len) {collect += s;}		 collect = collect.substr(0,len);		   return collect;	 };	   input += '';	 pad_string = pad_string !== undefined ? pad_string : ' ';		  if (pad_type != 'STR_PAD_LEFT' && pad_type != 'STR_PAD_RIGHT' && pad_type != 'STR_PAD_BOTH') { pad_type = 'STR_PAD_RIGHT'; }	 if ((pad_to_go = pad_length - input.length) > 0) {
		if (pad_type == 'STR_PAD_LEFT') { input = str_pad_repeater(pad_string, pad_to_go) + input; }
		else if (pad_type == 'STR_PAD_RIGHT') { input = input + str_pad_repeater(pad_string, pad_to_go); }
		else if (pad_type == 'STR_PAD_BOTH') {
			half = str_pad_repeater(pad_string, Math.ceil(pad_to_go/2));
			input = half + input + half;
			input = input.substr(0, pad_length);
		}
	}
 
	return input;
}

/**
 * SUBSTR_COUNT - conta o número de vezes que uma substring ocorre em uma string
 * @param string		Required.	Specifies the string to check.
 * @param substring		Required.	Specifies the string to search for.
 * @param start			Optional.	Specifies where in string to start searching.
 * @param length		Optional.	Specifies the length of the search.
 * @returns {Number}
 */
function substr_count(string,substring,start,length)	{
	var c = 0;
	if(start) { string = string.substr(start); }
	if(length) { string = string.substr(0,length); }
	for (var i=0;i<string.length;i++)	{
		if(substring == string.substr(i,substring.length))
			c++;
	}
	return c;
}

/**
 * Localiza a primeira ocorrência de uma string dentro de outra
 * 
 * @param haystack
 * @param needle
 * @param bool
 * @returns {String}
 */
function strstr (haystack, needle, bool) {
	// Finds first occurrence of a string within another  
	// 
	// version: 1109.2015
	// discuss at: http://phpjs.org/functions/strstr
	// +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
	// +   bugfixed by: Onno Marsman
	// +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
	// *	 example 1: strstr('Kevin van Zonneveld', 'van');
	// *	 returns 1: 'van Zonneveld'
	// *	 example 2: strstr('Kevin van Zonneveld', 'van', true);
	// *	 returns 2: 'Kevin '
	// *	 example 3: strstr('name@example.com', '@');
	// *	 returns 3: '@example.com'
	// *	 example 4: strstr('name@example.com', '@', true);
	// *	 returns 4: 'name'
	var pos = 0;
 
	haystack += '';
	pos = haystack.indexOf(needle);
	if (pos === -1) {
		return false;
	} else {
		if (bool) {
			return haystack.substr(0, pos);
		} else {
			return haystack.slice(pos);
		}
	}
}

/**
 * Valida E-mail
 * @param {string} campo
 * @returns {Boolean}
 */
function checarEmail(campo){
	x = 0;
	email=campo;
	carac_invalido = " /:,;";
	if (email === ""){
		return false;
	}
	for (i=0; i<carac_invalido.length; i++){
		carac_errado = carac_invalido.charAt(i);
		if (email.indexOf(carac_errado,0) > -1){
			return false;
		}
	}

	atPos = email.indexOf("@",1);
	if (atPos === -1){
		return false;
	}

	if (email.indexOf("@",atPos+1) !== -1){
		return false;
	}
	periodPos = email.indexOf(".",atPos);

	if (periodPos === -1){
		return false;
	}

	if (periodPos+3 > email.length){
		return false;
	}
	return true;
}

/**
 * Valida CPF
 * @param {string} campo
 * @returns {Boolean}
 */
function validaCPF(campo){
	var strCPF = campo.replace(/[^\d]+/g,'');
	var Soma;
	var Resto;
	Soma = 0;
	if (strCPF.length != 11 ||
		strCPF == "00000000000" ||
		strCPF == "11111111111" ||
		strCPF == "22222222222" ||
		strCPF == "33333333333" ||
		strCPF == "44444444444" ||
		strCPF == "55555555555" ||
		strCPF == "66666666666" ||
		strCPF == "77777777777" ||
		strCPF == "88888888888" ||
		strCPF == "99999999999")
		return false;

	for (i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
	Resto = (Soma * 10) % 11;

	if ((Resto == 10) || (Resto == 11))  Resto = 0;
	if (Resto != parseInt(strCPF.substring(9, 10)) ) return false;

	Soma = 0;
	for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
	Resto = (Soma * 10) % 11;

	if ((Resto == 10) || (Resto == 11))  Resto = 0;
	if (Resto != parseInt(strCPF.substring(10, 11) ) ) return false;
	return true;
}

/**
 * Valida DATA
 * @param {string} month
 * @param {string} day
 * @param {string} year
 * @returns {Boolean}
 */
function checkdate( month, day, year ) {
	// http://kevin.vanzonneveld.net
	// +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
	// *	 example 1: checkdate(12, 31, 2000);
	// *	 returns 1: true
	// *	 example 2: checkdate(2, 29, 2001);
	// *	 returns 2: false
	// *	 example 3: checkdate(03, 31, 2008);
	// *	 returns 3: true
	
	var myDate = new Date();
	myDate.setFullYear( year, (month - 1), day );
	
	return ( (myDate.getMonth()+1) === month );
}

/**
 * Validar CNPJ
 * @param {string} cnpj
 * @returns {Boolean}
 */
function validaCNPJ(cnpj) {
	cnpj = cnpj.replace(/[^\d]+/g,'');
 
	if(cnpj === '') return false;
	 
	if (cnpj.length !== 14)
		return false;
 
	// Elimina CNPJs invalidos conhecidos
	if (cnpj === "00000000000000" || 
		cnpj === "11111111111111" || 
		cnpj === "22222222222222" || 
		cnpj === "33333333333333" || 
		cnpj === "44444444444444" || 
		cnpj === "55555555555555" || 
		cnpj === "66666666666666" || 
		cnpj === "77777777777777" || 
		cnpj === "88888888888888" || 
		cnpj === "99999999999999")
		return false;
		 
	// Valida DVs
	tamanho = cnpj.length - 2;
	numeros = cnpj.substring(0,tamanho);
	digitos = cnpj.substring(tamanho);
	soma = 0;
	pos = tamanho - 7;
	for (i = tamanho; i >= 1; i--) {
		soma += numeros.charAt(tamanho - i) * pos--;
		if (pos < 2)
			pos = 9;
	}
	resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
	if (resultado !== digitos.charAt(0))
		return false;

	tamanho = tamanho + 1;
	numeros = cnpj.substring(0,tamanho);
	soma = 0;
	pos = tamanho - 7;
	for (i = tamanho; i >= 1; i--) {
		soma += numeros.charAt(tamanho - i) * pos--;
		if (pos < 2)
			pos = 9;
	}
	resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
	if (resultado !== digitos.charAt(1))
		return false;

	return true;
}

// Função para validação de CEP.
function isCEP(strCEP, blnVazio) {
	// Caso o CEP não esteja nesse formato ele é inválido!
	var objER = /^[0-9]{2}\.[0-9]{3}-[0-9]{3}$/;

	strCEP = $.trim(strCEP);
	if(strCEP.length > 0)
		{
			if(objER.test(strCEP))
				return true;
			else
				return false;
		}
	else
		return blnVazio;
}


/**
 * Gerar token
 *
 * @param {int} limit
 * @returns {string}
 */
function makeid(limit)
{
	limit = (!limit)? 5:limit;
	var text = "";
	var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

	for( var i=0; i < limit; i++ )
		text += possible.charAt(Math.floor(Math.random() * possible.length));

	return text;
}

/**
 * Verificar se o valor é vazio/nulo/undefined
 *
 * @param value
 * @returns {boolean}
 */
function empty (value) {
	if (value === undefined || value === null || value === "") {
		return true;
	}

	return false;
}